<template>
    <div id="page">
        <nav class="fh5co-nav" role="navigation">
            <!-- <div class="top-menu"> -->
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 text-center logo-wrap">
                        <div id="fh5co-logo" class='w-full flex justify-center items-center'> <img class=' w-40 lg:w-56' src="images/Energy Kids_11.png" alt=""></div>
                    </div>
                    <div class="col-xs-12 text-center menu-1 menu-wrap">
                        <ul>
                            <li onclick="window.location.reload();" class="active">
                                <router-link to="/">{{ $t('message.mainTitle') }}</router-link>
                            </li>
                            <li onclick="window.location.reload();"><router-link to="/about">{{ $t('message.mainAbout') }}</router-link></li>
                            <li onclick="window.location.reload();">
                                <router-link to="/discover">{{ $t('message.mainProduct') }}</router-link>
                            </li>
                            <li onclick="window.location.reload();">
                                <router-link to="/case-studies">{{ $t('message.mainTheme') }}</router-link>
                            </li>
                            <li class="has-dropdown">
                                <a class='selectedLanguageContainer'>{{ this.$i18n.locale }}</a>
                                <ul class="dropdown" style="">
                                    <li class="">
                                        <a @click="changeLanguage('ru')" id='ru-lang' class="cursor-pointer flex flex-row ru-lang"
                                            ><img class="w-10 pointer-events-none" src="images/rs_flag.png" alt="" />
                                            <div class='pointer-events-none language-text-container'>RU</div></a
                                        >
                                    </li>
                                    <li class="">
                                        <a @click="changeLanguage('kz')" id='kz-lang' class="cursor-pointer flex flex-row kz-lang"
                                            ><img class="w-10 pointer-events-none" src="images/kz_flag.png" alt="" />
                                            <div class='pointer-events-none language-text-container'>KZ</div></a
                                        >
                                    </li>
                                    <li class="">
                                        <a @click="changeLanguage('en')" id='en-lang' class="cursor-pointer flex flex-row kz-lang"
                                            ><img class="w-10 pointer-events-none" src="images/us_flag.png" alt="" />
                                            <div class='pointer-events-none language-text-container'>EN</div></a
                                        >
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- </div> -->
        </nav>

        <header
            id="fh5co-header"
            class="fh5co-cover js-fullheight index-bg-container"
            role="banner"
            style="background-image: url(images/first.png)"
            data-stellar-background-ratio="0.5"
        >
            <div class="overlay"></div>
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <div class="display-t js-fullheight">
                            <div class="display-tc js-fullheight animate-box" data-animate-effect="fadeIn">
                                <h1>{{ $t("message.indexTitle") }}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <div id="fh5co-about" class="fh5co-section animate-box">
            <div class="container">
                <div class="row serenity-item flex justify-center items-center px-12 md:px-96">
                    <div class="flex flex-col md:flex-row justify-center items-end">
                        <img
                            src="images/device_phone_1_1.png"
                            alt="Free Restaurant Bootstrap Website Template by FreeHTML5.co"
                        />
                        <div class="section-heading">
                            <p v-html='$t("message.indexFirst")'>
                            </p>
                            <!-- <p><a href="#" class="btn btn-primary btn-outline">Our History</a></p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="fh5co-featured-menu" class="fh5co-section">
        <div class="container">
            <div class="row">
                <div class="col-md-12 animate-box mb-10">
                    <h2 class="text-center text-5xl lg:text-5xl" style="color: white">
                        {{ $t("message.indexSecond") }}
                    </h2>
                    <!-- <div class="row">
                    <div class="col-md-6">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis ab debitis sit itaque totam, a maiores nihil, nulla magnam porro minima officiis! Doloribus aliquam voluptates corporis et tempora consequuntur ipsam, itaque, nesciunt similique commodi omnis. Ad magni perspiciatis, voluptatum repellat.</p>
                    </div>
                </div> -->
                </div>

                <div style="margin-top:50px;" class="col-md-3 col-sm-6 col-xs-6 col-xxs-12 fh5co-item-wrap animate-box">
                    <div class="fh5co-item flex justify-center items-center flex-col text-center">
                        <svg
                            class="custom-icon-outline"
                            preserveAspectRatio="xMidYMid meet"
                            data-bbox="29.5 29.5 141 141"
                            viewBox="29.5 29.5 141 141"
                            height="200"
                            width="200"
                            xmlns="http://www.w3.org/2000/svg"
                            data-type="color"
                            role="presentation"
                            aria-hidden="true"
                            aria-label=""
                        >
                            <g>
                                <path
                                    style="fill: white"
                                    d="M100 29.5c-38.874 0-70.5 31.626-70.5 70.5s31.626 70.5 70.5 70.5 70.5-31.626 70.5-70.5-31.626-70.5-70.5-70.5zm0 133c-34.463 0-62.5-28.038-62.5-62.5S65.537 37.5 100 37.5s62.5 28.038 62.5 62.5-28.037 62.5-62.5 62.5zm29.8-46.8c-.456.608-11.387 14.9-29.8 14.9s-29.344-14.292-29.8-14.9a4 4 0 0 1 6.393-4.81c.091.12 9.073 11.711 23.407 11.711 14.336 0 23.318-11.594 23.407-11.711a4.006 4.006 0 0 1 5.599-.776 3.994 3.994 0 0 1 .794 5.586zM73.583 80.05c0-3.59 2.877-6.5 6.467-6.5h.066a6.5 6.5 0 1 1 0 13c-3.59 0-6.533-2.91-6.533-6.5zm39.9 0c0-3.59 2.877-6.5 6.467-6.5h.066a6.5 6.5 0 1 1 0 13c-3.59 0-6.533-2.91-6.533-6.5z"
                                    fill="#000001"
                                    data-color="1"
                                ></path>
                            </g>
                        </svg>
                        <h3 style='margin-bottom:20px; font-size:27px;'>{{ $t("message.indexItemsFirstTitle") }}</h3>
                        <div class="item-description-smaller text-gray-400">
                            {{ $t("message.indexItemsFirstDesc") }}
                        </div>
                    </div>
                </div>
                <div style="margin-top:50px;" class="col-md-3 col-sm-6 col-xs-6 col-xxs-12 fh5co-item-wrap animate-box">
                    <div class="fh5co-item margin_top flex justify-center items-center flex-col text-center">
                        <svg
                            class="custom-icon-outline"
                            style="fill: white"
                            preserveAspectRatio="xMidYMid meet"
                            data-bbox="29 29 141.667 141.667"
                            viewBox="29 29 141.667 141.667"
                            height="200"
                            width="200"
                            xmlns="http://www.w3.org/2000/svg"
                            data-type="shape"
                            role="presentation"
                            aria-hidden="true"
                            aria-label=""
                        >
                            <g>
                                <path
                                    d="M99.833 29c39.059 0 70.834 31.775 70.834 70.833 0 39.059-31.775 70.834-70.834 70.834C60.775 170.667 29 138.892 29 99.833 29 60.775 60.775 29 99.833 29Zm0 8.333c-34.466 0-62.5 28.034-62.5 62.5 0 34.467 28.034 62.5 62.5 62.5 34.467 0 62.5-28.033 62.5-62.5 0-34.466-28.033-62.5-62.5-62.5Zm4.167 25v33.334h33.333V104H95.667V62.333H104Z"
                                    fill-rule="evenodd"
                                ></path>
                            </g>
                        </svg>
                        <h3 style='margin-bottom:20px; font-size:27px;'>{{ $t("message.indexItemsSecondTitle") }}</h3>
                        <div class="item-description-smaller text-gray-400">
                            {{ $t("message.indexItemsSecondDesc") }}
                        </div>
                    </div>
                </div>
                <div class="clearfix visible-sm-block visible-xs-block"></div>
                <div style="margin-top:50px;" class="col-md-3 col-sm-6 col-xs-6 col-xxs-12 fh5co-item-wrap animate-box">
                    <div class="fh5co-item flex justify-center items-center flex-col text-center">
                        <svg
                            class="custom-icon-outline"
                            style="fill: white"
                            preserveAspectRatio="xMidYMid meet"
                            data-bbox="33 25 133.333 150"
                            viewBox="33 25 133.333 150"
                            height="200"
                            width="200"
                            xmlns="http://www.w3.org/2000/svg"
                            data-type="shape"
                            role="presentation"
                            aria-hidden="true"
                            aria-label=""
                        >
                            <g>
                                <path
                                    d="M103.017 29.15A14.164 14.164 0 0 0 93 25l-.11.001a14.166 14.166 0 0 0-12.986 9.445c-4.14 11.723-8.096 20.893-14.841 27.599l-.01.009-24.152 24.152-.007.007A26.666 26.666 0 0 0 33 104.972v44.205a25.832 25.832 0 0 0 13.91 22.853l.014.007A26.67 26.67 0 0 0 58.776 175h83.391c9.204 0 16.666-7.462 16.666-16.667 0-3.538-1.102-6.819-2.983-9.518 6.143-2.455 10.483-8.462 10.483-15.482 0-4.978-2.182-9.446-5.642-12.5a16.626 16.626 0 0 0 5.642-12.5c0-4.978-2.182-9.446-5.642-12.5a16.626 16.626 0 0 0 5.642-12.5c0-9.204-7.462-16.666-16.666-16.666h-20.834c-.279 0-.557.007-.833.02v-.02h-26.676a68.336 68.336 0 0 0 5.843-27.49v-.01a14.17 14.17 0 0 0-4.15-10.018ZM114.396 75h-19.73a4.167 4.167 0 0 1-3.726-6.03l1.66-3.32.003-.007a60 60 0 0 0 6.23-26.48 5.833 5.833 0 0 0-5.778-5.83 5.833 5.833 0 0 0-5.293 3.888c-4.142 11.73-8.6 22.557-16.82 30.73L46.765 92.126a18.333 18.333 0 0 0-5.432 12.888v44.146a17.497 17.497 0 0 0 9.417 15.473 18.342 18.342 0 0 0 8.114 2.033h55.532a16.594 16.594 0 0 1-2.229-8.334c0-4.978 2.182-9.446 5.642-12.5a16.626 16.626 0 0 1-5.642-12.5c0-4.978 2.182-9.446 5.642-12.5a16.626 16.626 0 0 1-5.642-12.5c0-4.978 2.182-9.446 5.642-12.5a16.626 16.626 0 0 1-5.642-12.5c0-3.035.811-5.882 2.229-8.333Zm14.437 91.667h13.334a8.333 8.333 0 0 0 0-16.667h-13.334a8.333 8.333 0 0 0 0 16.667Zm0-91.667a8.333 8.333 0 0 0 0 16.667h20.834a8.333 8.333 0 0 0 0-16.667h-20.834Zm-8.333 33.333a8.333 8.333 0 0 1 8.333-8.333h20.834a8.333 8.333 0 0 1 0 16.667h-20.834a8.333 8.333 0 0 1-8.333-8.334ZM128.833 125a8.333 8.333 0 0 0 0 16.667h20.834a8.333 8.333 0 0 0 0-16.667h-20.834Z"
                                    fill-rule="evenodd"
                                ></path>
                            </g>
                        </svg>
                        <h3 style='margin-bottom:20px; font-size:27px;'>{{ $t("message.indexItemsThirdTitle") }}</h3>
                        <div class="item-description-smaller text-gray-400">
                            {{ $t("message.indexItemsThirdDesc") }}
                        </div>
                    </div>
                </div>
                <div style="margin-top:50px;" class="col-md-3 col-sm-6 col-xs-6 col-xxs-12 fh5co-item-wrap animate-box">
                    <div class="fh5co-item margin_top flex justify-center items-center flex-col text-center">
                        <svg
                            class="custom-icon-outline"
                            preserveAspectRatio="xMidYMid meet"
                            data-bbox="36.5 29.499 127 141.001"
                            viewBox="36.5 29.499 127 141.001"
                            height="200"
                            width="200"
                            xmlns="http://www.w3.org/2000/svg"
                            data-type="color"
                            role="presentation"
                            aria-hidden="true"
                            aria-label=""
                        >
                            <g>
                                <path
                                    style="fill: white"
                                    d="M163.126 85.006a3.966 3.966 0 0 0-3.595-2.306h-55.034l6.056-48.703a4.005 4.005 0 0 0-2.355-4.166 3.947 3.947 0 0 0-4.629 1.104l-66.146 79.8a4.024 4.024 0 0 0-.549 4.259 3.966 3.966 0 0 0 3.595 2.306h55.034l-6.056 48.703a4.005 4.005 0 0 0 2.355 4.166 3.95 3.95 0 0 0 4.628-1.104l66.146-79.8a4.023 4.023 0 0 0 .55-4.259zm-64.123 68.477 4.935-39.686a4.023 4.023 0 0 0-.962-3.145 3.956 3.956 0 0 0-2.976-1.353H48.956l52.041-62.783-4.935 39.686a4.023 4.023 0 0 0 .962 3.145A3.956 3.956 0 0 0 100 90.7h51.044l-52.041 62.783z"
                                    fill="#000001"
                                    data-color="1"
                                ></path>
                            </g>
                        </svg>
                        <h3 style='margin-bottom:20px; font-size:27px;'>{{ $t("message.indexItemsFourthTitle") }}</h3>
                        <div class="item-description-smaller text-gray-400">
                            {{ $t("message.indexItemsFourthDesc") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="fh5co-featured-testimony" class="fh5co-section">
        <div class="container">
            <div class="row">
                <div class="col-md-12 animate-box">
                    <h2 class="text-center text-5xl" style="color: white">{{ $t("message.howWorks") }}</h2>
                    <!-- <div class="row">
                    <div class="col-md-6">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis ab debitis sit itaque totam, a maiores nihil, nulla magnam porro minima officiis! Doloribus aliquam voluptates corporis et tempora consequuntur ipsam, itaque, nesciunt similique commodi omnis.</p>
                    </div>
                </div> -->
                    <br />
                </div>

                <div class="col-md-12 grid grid-cols-1 md:grid-cols-2 items-start md:px-36 lg:px-60 gap-10">
                    <div class="flex justify-center flex-col text-center items-center animate-box">
                        <img class="item-image" src="images/about_download.png" alt="" />
                        <div class="item-title">1. {{ $t("message.download") }}</div>
                        <div class="item-description text-gray-400">
                            {{ $t("message.downloadDescription")}}
                        </div>
                    </div>
                    <div class="flex justify-center flex-col text-center items-center animate-box">
                        <img class="item-image" src="images/about_wear.png" alt="" />
                        <div class="item-title">2. {{ $t("message.setup") }}</div>
                        <div class="item-description text-gray-400">
                            {{ $t("message.setupDescription") }}
                        </div>
                    </div>
                    <div class="flex justify-center flex-col text-center items-center animate-box">
                        <img class="item-image" src="images/about_enable.png" alt="" />
                        <div class="item-title">3. {{ $t("message.run") }}</div>
                        <div class="item-description text-gray-400">
                            {{ $t("message.runDescription") }}
                        </div>
                    </div>
                    <div class="flex justify-center flex-col text-center items-center animate-box">
                        <img class="item-image" src="images/about_result.png" alt="" />
                        <div class="item-title">4. {{ $t("message.result") }}</div>
                        <div class="item-description text-gray-400">
                            {{ $t("message.resultDescription") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        id="fh5co-started"
        class="fh5co-section animate-box"
        style="background-image: url(images/second.jpeg); background-position: center;"
        data-stellar-background-ratio="1.0"
    >
        <div class="overlay"></div>
        <div class="container">
            <div class="row animate-box">
                <div class="col-md-8 col-md-offset-2 text-center fh5co-heading">
                    <div class="fh5co-heading mb-5">
                        <h1 class="text-5xl text-white">{{ $t("message.moreInformation") }}...</h1>
                        <br /><br />
                    </div>
                    <div class="text-4xl text-white" v-html='$t("message.moremoreInformation")'>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="fh5co-blog" class="fh5co-section" style="height: fit-content">
        <div class="container">
            <div class="row animate-box">
                <div class="col-md-12 text-center animate-box">
                    <section id="slider" class="container">
                        <ul class="slider-wrapper h-full">
                            <li class="current-slide">
                                <div class="caption-container flex items-center justify-center h-full">
                                    <div class="rounded-lg bg-gray-600 p-20 card-item">
                                        <h2 class="text-white text-4xl lg:text-5xl mb-5" style='font-weight:900;'>{{ $t("message.groupResearchTitle") }}</h2>
                                        <p class="item-description " style='color: rgb(196, 196, 196); font-weight:600; font-size:22px' v-html='$t("message.groupResearchDescription")'>
                                        </p>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div class="caption-container flex items-center justify-center h-full">
                                    <div class="rounded-lg bg-gray-600 p-20 card-item">
                                        <h2 class="text-white text-4xl lg:text-5xl mb-5" style='font-weight:900;'>
                                            {{ $t("message.practiceFirst") }}
                                        </h2>
                                        <p class="item-description " style='color: rgb(196, 196, 196); font-weight:600; font-size:22px' v-html='$t("message.practiceFirstText")'>
                                        </p>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div class="caption-container flex items-center justify-center h-full">
                                    <div class="rounded-lg bg-gray-600 p-20 card-item">
                                        <h2 class="text-white text-4xl lg:text-5xl mb-5" style='font-weight:900;'>
                                            {{ $t("message.practice2") }}
                                        </h2>
                                        <p class="item-description " style='color: rgb(196, 196, 196); font-weight:600; font-size:22px' v-html='$t("message.practice2Text")'>
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <!-- Controles de Navegacion -->
                        <ul id="control-buttons" class="control-buttons"></ul>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Index',
    data() {
        return {
            currentLanguage: this.$i18n.locale,
        }
    },
    methods: {
        changeLanguage(lang) {
            console.log(lang)
            this.$i18n.locale = lang
            localStorage.setItem('selectedLanguage', lang);
        },
    },
}/* eslint-disable */
</script>

<style>
#comp-lng51z011 svg [data-color='1'] {
    fill: #ffffff;
}
</style>
