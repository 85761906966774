<template>
    <footer id="fh5co-footer" role="contentinfo" class="fh5co-section">
        <div class="container flex justify-center">
            <div class="col-md-8 flex justify-around">
                <div class="fh5co-widget">
                    <ul class="fh5co-footer-links">
                            <li onclick="window.location.reload();">
                                <router-link to="/">{{ $t('message.mainTitle') }}</router-link>
                            </li>
                            <li onclick="window.location.reload();"><router-link to="/about">{{ $t('message.mainAbout') }}</router-link></li>
                            <li onclick="window.location.reload();">
                                <router-link to="/discover">{{ $t('message.mainProduct') }}</router-link>
                            </li>
                            <li onclick="window.location.reload();">
                                <router-link to="/case-studies">{{ $t('message.mainTheme') }}</router-link>
                            </li>
                    </ul>
                </div>

                <div class="fh5co-widget">
                    <ul class="fh5co-footer-links">
                        <li onclick="window.location.reload();"><router-link to="/warranty-terms">{{ $t("message.warrantyTerms") }}</router-link></li>
                        <li onclick="window.location.reload();"><router-link to="/purchase-agreement">{{ $t("message.purchaseAgreement")}}</router-link></li>
                        <li onclick="window.location.reload();"><router-link to="/privacy-policy">{{ $t("message.privacy") }}</router-link></li>
                    </ul>
                </div>

                <!-- <div class="fh5co-widget">
                    <ul class="fh5co-footer-links">
                        <li>Facebook</li>
                        <li><a href="tel://1234567920">Instagram</a></li>
                        <li><a href="mailto:info@yoursite.com">TikTok</a></li>
                    </ul>
                </div> -->
            </div>
        </div>
        <div class="copyright">
            <div class="w-full text-center">
                <p>&copy; 2024 EnergyKID.</p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
}/* eslint-disable */
</script>
