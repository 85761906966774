<template>
    <li class="bg-gray-600 my-2 shadow-lg rounded-md">
        <h2 @click="toggleTab" class="flex flex-row justify-between items-center font-semibold p-3 cursor-pointer">
            <span  class="text-4xl text-white"> {{ title }} </span>
        </h2>
        <div
            class="border-l-2 border-white overflow-hidden max-h-0 duration-500 transition-all "
            ref="tab"
            :style="tabStyle"
        >
            <div class=" text-gray-300 p-3 font-medium text-3xl " v-html="content"></div>
        </div>
    </li>
</template>

<script>
export default {
    props: {
        title: String,
        content: String,
    },
    data() {
        return {
            isTabOpen: false,
        }
    },
    methods: {
        toggleTab() {
            this.isTabOpen = !this.isTabOpen
        },
    },
    computed: {
        tabStyle() {
            return {
                maxHeight: this.isTabOpen ? `${this.$refs.tab.scrollHeight}px` : '0',
            }
        },
    },
}
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
