<template>
    <div id="page">
        <nav class="fh5co-nav" role="navigation">
            <!-- <div class="top-menu"> -->
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 text-center logo-wrap">
                        <div id="fh5co-logo" class='w-full flex justify-center items-center'> <img class=' w-40 lg:w-56' src="images/Energy Kids_11.png" alt=""></div>
                    </div>
                    <div class="col-xs-12 text-center menu-1 menu-wrap">
                        <ul>
                            <li onclick="window.location.reload();">
                                <router-link to="/">{{ $t('message.mainTitle') }}</router-link>
                            </li>
                            <li onclick="window.location.reload();"><router-link to="/about">{{ $t('message.mainAbout') }}</router-link></li>
                            <li onclick="window.location.reload();">
                                <router-link to="/discover">{{ $t('message.mainProduct') }}</router-link>
                            </li>
                            <li onclick="window.location.reload();">
                                <router-link to="/case-studies">{{ $t('message.mainTheme') }}</router-link>
                            </li>
                            <li class="has-dropdown">
                                <a>{{ this.$i18n.locale }}</a>
                                <ul class="dropdown" style="">
                                    <li class="">
                                        <a @click="changeLanguage('ru')" id='ru-lang' class="cursor-pointer flex flex-row ru-lang"
                                            ><img class="w-10 pointer-events-none" src="images/rs_flag.png" alt="" />
                                            <div class='pointer-events-none language-text-container'>RU</div></a
                                        >
                                    </li>
                                    <li class="">
                                        <a @click="changeLanguage('kz')" id='kz-lang' class="cursor-pointer flex flex-row kz-lang"
                                            ><img class="w-10 pointer-events-none" src="images/kz_flag.png" alt="" />
                                            <div class='pointer-events-none language-text-container'>KZ</div></a
                                        >
                                    </li>
                                    <li class="">
                                        <a @click="changeLanguage('en')" id='en-lang' class="cursor-pointer flex flex-row kz-lang"
                                            ><img class="w-10 pointer-events-none" src="images/us_flag.png" alt="" />
                                            <div class='pointer-events-none language-text-container'>EN</div></a
                                        >
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- </div> -->
        </nav>

        <header
            id="fh5co-header"
            class="fh5co-cover js-fullheight index-bg-container"
            role="banner"
            style="background-image: url(images/warranty.jpg)"
            data-stellar-background-ratio="0.5"
        >
            <div class="overlay"></div>
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <div class="display-t js-fullheight">
                            <div class="display-tc js-fullheight animate-box" data-animate-effect="fadeIn">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        
    </div>

    <div id="fh5co-featured-menu" class="fh5co-section " style='margin-top:0; padding-top:0'>
        <div class="container">
            <div class="row">
                <div class="col-md-12 animate-box mb-10">
                    <h2 class="text-center text-5xl lg:text-5xl" style="color: white">
                        
                    </h2>

                    <div id="fh5co-about" class="fh5co-section" style="padding-bottom: 0; margin-top:0">
                        <div class="container">
                            <div class="row flex justify-center flex-col items-center">
                                <div class="font-bold text-white bg-gray-600 rounded-lg px-7 py-5 text-4xl w-2/3 lg:w-2/4">
                                    {{ $t("message.warrantyTerms") }}
                                </div>
                                <br />
                                <div class="text-4xl w-2/3 lg:w-2/4">
                                    <div v-html='$t("message.warrantyTermsContent")'></div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
                </div>

                
            </div>
        </div>
    </div>

    
</template>

<script>
export default {
    name: 'Index',
    data() {
        return {
            currentLanguage: this.$i18n.locale,
        }
    },
    methods: {
        changeLanguage(lang) {
            console.log(lang)
            this.$i18n.locale = lang
            localStorage.setItem('selectedLanguage', lang);
        },
    },
}/* eslint-disable */
</script>

<style>
#comp-lng51z011 svg [data-color='1'] {
    fill: #ffffff;
}
</style>
