<template>
    <div id="page">
        <nav class="fh5co-nav" role="navigation">
            <!-- <div class="top-menu"> -->
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 text-center logo-wrap">
                        <div id="fh5co-logo" class='w-full flex justify-center items-center'> <img class=' w-40 lg:w-56' src="images/Energy Kids_11.png" alt=""></div>
                    </div>
                    <div class="col-xs-12 text-center menu-1 menu-wrap">
                        <ul>
                            <li onclick="window.location.reload();">
                                <router-link to="/">{{ $t('message.mainTitle') }}</router-link>
                            </li>
                            <li onclick="window.location.reload();" class="active">
                                <router-link to="/about">{{ $t('message.mainAbout') }}</router-link>
                            </li>
                            <li onclick="window.location.reload();">
                                <router-link to="/discover">{{ $t('message.mainProduct') }}</router-link>
                            </li>
                            <li onclick="window.location.reload();">
                                <router-link to="/case-studies">{{ $t('message.mainTheme') }}</router-link>
                            </li>
                            <li class="has-dropdown">
                                <a>{{ this.$i18n.locale }}</a>
                                <ul class="dropdown" style="">
                                    <li class="">
                                        <a @click="changeLanguage('ru')" id='ru-lang' class="cursor-pointer flex flex-row ru-lang"
                                            ><img class="w-10 pointer-events-none" src="images/rs_flag.png" alt="" />
                                            <div class='pointer-events-none language-text-container'>RU</div></a
                                        >
                                    </li>
                                    <li class="">
                                        <a @click="changeLanguage('kz')" id='kz-lang' class="cursor-pointer flex flex-row kz-lang"
                                            ><img class="w-10 pointer-events-none" src="images/kz_flag.png" alt="" />
                                            <div class='pointer-events-none language-text-container'>KZ</div></a
                                        >
                                    </li>
                                    <li class="">
                                        <a @click="changeLanguage('en')" id='en-lang' class="cursor-pointer flex flex-row kz-lang"
                                            ><img class="w-10 pointer-events-none" src="images/us_flag.png" alt="" />
                                            <div class='pointer-events-none language-text-container'>EN</div></a
                                        >
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- </div> -->
        </nav>

        <header
            id="fh5co-header"
            class="fh5co-cover js-fullheight"
            role="banner"
            style="background-image: url(images/about-bg.jpg)"
            data-stellar-background-ratio="0.5"
        >
            <div class="overlay"></div>
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <div class="display-t js-fullheight">
                            <div class="display-tc js-fullheight animate-box" data-animate-effect="fadeIn">
                                <h1>{{ $t('about.aboutTitle') }}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <div id="fh5co-about" class="fh5co-section" style="padding-bottom: 0">
            <div class="container">
                <div class="row flex justify-center flex-col items-center">
                    <div class="w-2/3 lg:w-2/4">
                        <div class="text-4xl">
                            <span class="text-3xl">{{ $t('about.ourMission') }}:</span><br />
                            {{ $t('about.first') }}
                        </div>
                    </div>
                    <br />
                    <div class="font-bold text-white bg-gray-600 rounded-lg px-7 py-5 text-4xl w-2/3 lg:w-2/4">
                        {{ $t('about.second') }}
                    </div>
                    <br />
                    <div class="text-4xl w-2/3 lg:w-2/4">
                        <span class="font-bold">{{ $t('about.aboutCompany') }}:</span>
                        <div v-html='$t("about.aboutBigText")'></div>
                    </div>
                    <br /><br /><br /><br />
                    <div class="font-bold text-white bg-gray-600 rounded-lg px-7 py-5 text-4xl w-2/3 lg:w-2/4">
                        {{ $t('about.history') }}
                    </div>
                </div>
            </div>
        </div>

        <div id="fh5co-timeline" style="padding-top: 15px">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-md-offset-0">
                        <ul class="timeline animate-box">
                            <li class="animate-box timeline-unverted">
                                <div class="timeline-badge"><i class="icon-genius"></i></div>
                                <div class="timeline-panel">
                                    <div class="timeline-heading">
                                        <h3 class="timeline-title">1988 {{ $t('about.year') }}</h3>
                                    </div>
                                    <div class="timeline-body">
                                        <p>
                                            {{ $t('about.oldest') }}
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li class="timeline-inverted animate-box">
                                <div class="timeline-badge"><i class="icon-genius"></i></div>
                                <div class="timeline-panel">
                                    <div class="timeline-heading">
                                        <h3 class="timeline-title">1999 {{ $t('about.year') }}</h3>
                                    </div>
                                    <div class="timeline-body">
                                        <p>
                                            {{ $t('about.older') }}
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li class="animate-box timeline-unverted">
                                <div class="timeline-badge"><i class="icon-genius"></i></div>
                                <div class="timeline-panel">
                                    <div class="timeline-heading">
                                        <h3 class="timeline-title">2007 {{ $t('about.year') }}</h3>
                                    </div>
                                    <div class="timeline-body">
                                        <p>
                                            {{ $t('about.new') }}
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li class="timeline-inverted animate-box">
                                <div class="timeline-badge"><i class="icon-genius"></i></div>
                                <div class="timeline-panel">
                                    <div class="timeline-heading">
                                        <h3 class="timeline-title">2017 {{ $t('about.year') }}</h3>
                                    </div>
                                    <div class="timeline-body">
                                        <p>
                                            {{ $t('about.newer') }}
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li class="animate-box timeline-unverted">
                                <div class="timeline-badge"><i class="icon-genius"></i></div>
                                <div class="timeline-panel">
                                    <div class="timeline-heading">
                                        <h3 class="timeline-title">2021 {{ $t('about.year') }}</h3>
                                    </div>
                                    <div class="timeline-body">
                                        <p>
                                            {{ $t('about.newest') }}
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'About',
    data() {
        return {
            currentLanguage: this.$i18n.locale,
        }
    },
    methods: {
        changeLanguage(lang) {
            console.log(lang)
            this.$i18n.locale = lang
            localStorage.setItem('selectedLanguage', lang)
        },
    },/* eslint-disable */
}
</script>
