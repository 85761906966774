<template>
    <div id="page">
        <nav class="fh5co-nav" role="navigation">
            <!-- <div class="top-menu"> -->
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 text-center logo-wrap">
                        <div id="fh5co-logo" class='w-full flex justify-center items-center'> <img class=' w-40 lg:w-56' src="images/Energy Kids_11.png" alt=""></div>
                    </div>
                    <div class="col-xs-12 text-center menu-1 menu-wrap">
                        <ul>
                            <li onclick="window.location.reload();">
                                <router-link to="/">{{ $t('message.mainTitle') }}</router-link>
                            </li>
                            <li onclick="window.location.reload();">
                                <router-link to="/about">{{ $t('message.mainAbout') }}</router-link>
                            </li>
                            <li onclick="window.location.reload();" class="active">
                                <router-link to="/discover">{{ $t('message.mainProduct') }}</router-link>
                            </li>
                            <li onclick="window.location.reload();">
                                <router-link to="/case-studies">{{ $t('message.mainTheme') }}</router-link>
                            </li>
                            <li class="has-dropdown">
                                <a>{{ this.$i18n.locale }}</a>
                                <ul class="dropdown" style="">
                                    <li class="">
                                        <a @click="changeLanguage('ru')" id='ru-lang' class="cursor-pointer flex flex-row ru-lang"
                                            ><img class="w-10 pointer-events-none" src="images/rs_flag.png" alt="" />
                                            <div class='pointer-events-none language-text-container'>RU</div></a
                                        >
                                    </li>
                                    <li class="">
                                        <a @click="changeLanguage('kz')" id='kz-lang' class="cursor-pointer flex flex-row kz-lang"
                                            ><img class="w-10 pointer-events-none" src="images/kz_flag.png" alt="" />
                                            <div class='pointer-events-none language-text-container'>KZ</div></a
                                        >
                                    </li>
                                    <li class="">
                                        <a @click="changeLanguage('en')" id='en-lang' class="cursor-pointer flex flex-row kz-lang"
                                            ><img class="w-10 pointer-events-none" src="images/us_flag.png" alt="" />
                                            <div class='pointer-events-none language-text-container'>EN</div></a
                                        >
                                    </li>
                                </ul>
                            </li>
                            
                        </ul>
                    </div>
                </div>
            </div>
            <!-- </div> -->
        </nav>

        <header
            id="fh5co-header"
            class="fh5co-cover js-fullheight index-bg-container"
            role="banner"
            style="background-image: url(images/asian-girl.png)"
            data-stellar-background-ratio="0.5"
        >
            <div class="overlay"></div>
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <div class="display-t js-fullheight">
                            <div class="display-tc js-fullheight animate-box" data-animate-effect="fadeIn">
                                <h1 v-html='$t("product.firstTitle")'>
                                    
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <div id="fh5co-about" class="fh5co-section" style="padding-bottom: 0">
            <div class="container">
                <div class="row flex justify-center flex-col items-center">
                    <div class="font-bold text-white bg-gray-600 rounded-lg px-7 py-5 text-4xl w-2/3 lg:w-2/4">
                        {{ $t("product.whatDoes") }}
                    </div>
                    <br />
                    <div class="text-4xl w-2/3 lg:w-2/4" v-html='$t("product.content")'>
                        
                    </div>

                    <main class="p-5 bg-light-blue mt-40 w-full">
                        <div class="flex justify-center items-start my-2">
                            <div class="w-full sm:w-10/12 md:w-1/2 my-1">
                                <h2 class="text-white text-5xl mt-20 w-full px-4 md:px-0">{{ $t("product.frequentQuestions") }}</h2>
                                <ul class="flex flex-col w-full px-4 md:px-0">
                                    <AccordionItem
                                        v-for="(item, index) in accordionItems"
                                        :key="index"
                                        :title="item.title"
                                        :content="item.content"
                                    />
                                    
                                </ul>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AccordionItem from './AccordionItem.vue'

export default {
    name: 'Discover',
    components: {
        AccordionItem,
    },
    data() {
        return {
            accordionItems: [
                {
                    title: this.$t("product.firstQuestionTitle"),
                    content: this.$t("product.firstQuestionContent"),
                },
                {
                    title: this.$t("product.secondQuestionTitle"),
                    content: this.$t("product.secondQuestionContent"),
                },
                {
                    title: this.$t("product.thirdQuestionTitle"),
                    content: this.$t("product.thirdQuestionContent"),
                },
                {
                    title: this.$t("product.fourthQuestionTitle"),
                    content: this.$t("product.fourthQuestionContent"),
                },
                {
                    title: this.$t("product.fifthQuestionTitle"),
                    content: this.$t("product.fifthQuestionContent"),
                },
                {
                    title: this.$t("product.sixthQuestionTitle"),
                    content: this.$t("product.sixthQuestionContent"),
                },
                {
                    title: this.$t("product.seventhQuestionTitle"),
                    content: this.$t("product.seventhQuestionContent"),
                },
                {
                    title: this.$t("product.eighthQuestionTitle"),
                    content: this.$t("product.eighthQuestionContent"),
                },
                {
                    title: this.$t("product.ninthQuestionTitle"),
                    content: this.$t("product.ninthQuestionContent"),
                },
                {
                    title: this.$t("product.tenthQuestionTitle"),
                    content: this.$t("product.tenthQuestionContent"),
                },
                {
                    title: this.$t("product.eleventhQuestionTitle"),
                    content: this.$t("product.eleventhQuestionContent"),
                },
                {
                    title: this.$t("product.twelveQuestionTitle"),
                    content: this.$t("product.twelveQuestionContent"),
                },
                // Add more items as needed from your JSON data
            ],
            currentLanguage: this.$i18n.locale,
        }
    },
    methods: {
        changeLanguage(lang) {
            console.log(lang)
            this.$i18n.locale = lang
            localStorage.setItem('selectedLanguage', lang)
        },
    },/* eslint-disable */
}
</script>
