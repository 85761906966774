<template>
    <head>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <title>ENERGY KID</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
    </head>
</template>

<script>
export default {
    name: 'Header',
}/* eslint-disable */
</script>
